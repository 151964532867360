import React from 'react'
import { graphql, navigate } from 'gatsby';
import { Translation } from 'react-i18next';
import { withI18next } from '@wapps/gatsby-plugin-i18next';
import Layout from 'layouts/index'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {cancelRequest} from "store/modules/request";

import InputText from "macros/form/InputText";
import InputEmail from "macros/form/InputEmail";

import analytics from 'utils/analytics'

class CancelPage extends React.Component {
    state = {
        values: {
            email: this.props.isDev ? 'lluis@crowd.st' : '',
            ref: this.props.isDev ? '5bf7ad93dbe51' : ''
        },
        errors: {}
    }

    componentDidUpdate() {
        const {actualRequest} = this.props;
        if(actualRequest) {
            navigate('/cancel/success');
        }
    }
    componentDidMount(){
        analytics({"eventName":"cancelBook"})
    }
    changeInput(ev){
        const{name, value} = ev.target;
        const {values} = this.state;
        values[name] = value;
        this.setState({values});
    }
    handleFormSubmit(ev) {
        ev.preventDefault();
        const form = ev.target;

        const ref = form.elements["ref"].value;
        const email = form.elements["email"].value;

        this.props.cancelRequest(ref, email);
    }
    render() {
        const {values, errors} = this.state;
        const formOptions = {
            values,
            errors,
            onChange: this.changeInput.bind(this)
        }
        return (<Translation>{t => {
            formOptions.t = t;
            return (<Layout name={'cancelIndexPage'} title={t('cancelTitle')} {...this.props}>
                <div className="content-block container-fuild">
                    <div className="row">
                        <div className="content-block-content col-xs-12 col-sm-8 col-md-6">
                            <h4>{t('seatFactoryTours')}</h4>
                            <h2>{t('cancelTitle')}</h2>
                            <p>{t('cancelText')}</p>
                            <form className="container-fluid no-lateral-paddings" onSubmit={this.handleFormSubmit.bind(this)}>
                                <div className="row">
                                    <InputEmail name="email" label="email" {...formOptions}/>
                                    <InputText name="ref" label="requestNumber" {...formOptions}/>
                                </div>
                                <p>{t('cancelAdditionalnformation')}</p>
                                <button type="submit" className="btn btn-accept with-margin-top-small">{t('cancelButton')}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        )}}</Translation>);
    }
}

const mapStateToProps = state => ({
    isDev: state.main.isDev,
    actualRequest: state.request.actualRequest
})

const mapDispatchToProps = dispatch => bindActionCreators({
    cancelRequest
}, dispatch)

export const query = graphql`
  query($lng: String!){
    locales: allLocale(filter: { lng: { eq:$lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }
    site {
      siteMetadata {
        siteTitle
        activeEnv
        analytics
      }
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(withI18next()(CancelPage));
